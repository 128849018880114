export const environment = {
    development: false,
    qa: true,
    production: false,
    name: 'qa',
    domain: 'localhost',
    apiUrl: 'https://selected-managed.azurewebsites.net/api/proxy/volvoselected/qa/api/',
    signalrUrl: '/volvoselected/qa/api/',
    cdnUrl: 'https://volvoselectedqa.blob.core.windows.net/',
    tokenProxyUrl: 'https://tokenproxy-managed.azurewebsites.net/api/TokenProxyV2Function',
    recaptchaSitekey: '6LepUt8ZAAAAAASiSYS4G3hfgIuJ_KKWRC35bU5U', 
    
    ukSiteUrl: 'qa.volvousedtrucks.co.uk',
    nsSiteUrl:'qa.usedtrucks.volvonorthandscotland.co.uk',
    txSiteUrl:'qa.tradeandexport.volvotrucks.com',
    txGreenSiteUrl: 'qa.tradeandexport.volvotrucks.com',
    selectedSiteUrl: 'qa.volvoselected.com', 

    ukwebsite: "https://qa.volvousedtrucks.co.uk",
    nswebsite: "https://qa.usedtrucks.volvonorthandscotland.co.uk",
    txwebsite: "https://qa.tradeandexport.volvotrucks.com",
};