import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { HttpClient, HttpHandler } from '@angular/common/http';
import * as merge from 'lodash/fp/merge';
const { v4: uuidv4 } = require('uuid');
import { AppState } from '~/core/store';

import { ResetStep, GetSearch, SetSearchFromUrl, UpdateSearch, UpdateStep, GetAggregations, SetDefaultCategory } from '~/core/store/search/search.actions';
import {
  SearchResponse,
  SearchStateFilter,
  SearchStateInterface,
  SearchStateParams,
  SearchStep,
  SearchStepSliderValue,
  SortOptions
} from '~/core/store/search/search.model';
import { SearchApiService } from '~/core/api/search-api/search-api.service';
import { TrucksState } from '~/core/store/trucks/trucks.state';
import { ApiUtils } from '~/core/utils/api-utils/api-utils';
import { HtmlRoutePages } from '~/app-routing.model';
import { Direction, SearchQuery, SortField } from '~/core/models/search-query.model';
import { Truck } from '../trucks/trucks.model';
import { filter, map, take } from 'rxjs/operators';
import { LocalesStateInterface, LocalesStateCountryList } from '../locales/locales.model';
import { Event as NavigationEvent } from "@angular/router";
import { NavigationStart } from "@angular/router";
import { Injectable } from '@angular/core';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { RegionalSettingsService } from '../regionalSettings/regionalSettings.service';

export function isObject(x) {
  return x != null && typeof x === 'object';
}

@State<SearchStateInterface>({
  name: 'search',
  defaults: {
    params: {
      qf: null
    },
    filter: {
      q: '',
      requestingFilter: '',
      expandingFilter: '',
      defaultCategory: 'All',
      steps: {
        advertisementCategory: {
          id: uuidv4(),
          isSelected: false,
          isVisible: false,
          isExpanded: false,
          title: 'Offer',
          inputPlaceholder: '',
          value: 'All',
          initValue: 'All',
          list: [
            {
              name: 'Volvo Selected',
              id: 'VolvoSelected'
            },
            {
              name: 'Volvo Approved',
              id: 'VolvoApproved'
            },
            {
              name: 'Volvo Economy',
              id: 'VolvoEconomy'
            },
            {
              name: 'Trade and Export',
              id: 'TradeExport'
            }
          ],
        },
        warranty: {
          id: uuidv4(),
          isSelected: false,
          isVisible: false,
          isExpanded: false,
          title: 'Warranty',
          inputPlaceholder: '',
          value: 'All',
          initValue: 'All',
          list: [
            {
              name: '24 months',
              id: '24'
            },
            {
              name: '12 months',
              id: '12'
            },
            {
              name: '6 months',
              id: '6'
            },
          ],
        },
        majorCategory: {
          id: uuidv4(),
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          title: 'Vehicle Type',
          inputPlaceholder: '',
          value: 'All',
          initValue: 'All',
          list: [
            {
              name: 'Tractor',
              id: 'Tractor',
              icon: 'assets/svg/search/tractor.svg'
            },
            {
              name: 'Truck',
              id: 'Truck',
              icon: 'assets/svg/search/truck.svg'
            },
            {
              name: 'Trailer',
              id: 'Trailer',
              icon: 'assets/svg/search/Trailers.svg'
            }
          ]
        },
        offerAndWarranty: {
          id: uuidv4(),
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          title: 'Offer and Warranty',
          inputPlaceholder: '',
          value: 'All',
          initValue: 'All',
          list: [],
        },
        superstructureType: {
          id: uuidv4(),
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          title: 'Superstructure',
          inputPlaceholder: '',
          value: 'All',
          initValue: 'All',
          list: [
            {
              name: 'Box',
              id: 'Box'
            },
            {
              name: 'Chassis Cab',
              id: 'Chassis Cab'
            },
            {
              name: 'Container Lifts',
              id: 'Container Lifts'
            },
            {
              name: 'Platform',
              id: 'Platform'
            },
            {
              name: 'Special',
              id: 'Special'
            },
            {
              name: 'Swap Body Carrier',
              id: 'Swap Body Carrier'
            },
            {
              name: 'Tank & Bulk',
              id: 'Tank & Bulk'
            },
            {
              name: 'Timber',
              id: 'Timber'
            },
            {
              name: 'Tipper',
              id: 'Tipper'
            },
            {
              name: 'Superstructure',
              id: 'Superstructure'
            },
            {
              name: 'Other',
              id: 'Other'
            }
          ]
        },
        make: {
          id: uuidv4(),
          title: 'Make',
          inputPlaceholder: '',
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          value: 'All',
          initValue: 'All',
          list: []
        },
        model: {
          id: uuidv4(),
          title: 'Model',
          inputPlaceholder: 'Search for model',
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          value: '',
          initValue: 'All',
          viewMoreLimit: 5,
          initViewMoreLimit: 5,
          list: []
        },
        dateFirstRegistration: {
          id: uuidv4(),
          title: 'First Registration',
          inputPlaceholder: '',
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          value: {
            min: 1990,
            max: new Date().getFullYear()
          },
          initValue: {
            min: 1990,
            max: new Date().getFullYear()
          },
          options: {
            units: '',
            step: 1
          }
        },
        mileageKm: {
          id: uuidv4(),
          title: 'Mileage',
          inputPlaceholder: '',
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          value: {
            min: 0,
            max: 2000000
          },
          initValue: {
            min: 0,
            max: 2000000
          },
          options: {
            units: 'km',
            step: 10000
          }
        },
        axleConfiguration: {
          id: uuidv4(),
          title: 'Axle Configuration',
          inputPlaceholder: '',
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          value: 'All',
          initValue: 'All',
          viewMoreLimit: 5,
          initViewMoreLimit: 5,
          list: []
        },
        stockLocationCity: {
          id: uuidv4(),
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          title: 'Stocklocation City',
          inputPlaceholder: '',
          value: 'All',
          initValue: 'All',
          list: [],
        },
        cabType: {
          id: uuidv4(),
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          title: 'Cab',
          inputPlaceholder: '',
          value: 'All',
          initValue: 'All',
          list: [],
        },
        horsePower: {
          id: uuidv4(),
          title: 'Engine HP',
          inputPlaceholder: '',
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          value: {
            min: 0,
            max: 800
          },
          initValue: {
            min: 0,
            max: 800
          },
          options: {
            units: 'hp',
            step: 50
          }
        },
        fuelType: {
          id: uuidv4(),
          title: 'Fuel Type',
          inputPlaceholder: '',
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          value: 'All',
          initValue: 'All',
          viewMoreLimit: 5,
          initViewMoreLimit: 5,
          list: []
        },
        emission: {
          id: uuidv4(),
          title: 'Euronorm',
          inputPlaceholder: '',
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          value: 'All',
          initValue: 'All',
          viewMoreLimit: 3,
          initViewMoreLimit: 3,
          list: []
        },
        priceExclVatEuro: {
          id: uuidv4(),
          title: 'Price',
          inputPlaceholder: '',
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          value: {
            min: 0,
            max: 300000
          },
          initValue: {
            min: 0,
            max: 300000
          },
          options: {
            units: '€',
            step: 10000
          }
        },
        stockLocationAddress: {
          id: uuidv4(),
          isSelected: false,
          isVisible: false,
          isExpanded: false,
          title: 'Dealership Location',
          inputPlaceholder: '',
          value: '',
          initValue: '',
          list: []
        },
        stockLocationCountry: {
          id: uuidv4(),
          isSelected: false,
          isVisible: true,
          isExpanded: false,
          title: 'Truck Location',
          inputPlaceholder: 'All countries',
          value: '',
          initValue: '',
          list: []
        },
      },
      page: 1,
      sort: SortOptions.oldestAge
    },
    aggregations: {},
    totalTrucks: 0,
    grandTotalTrucks: 0,
    totalOtherTrucks: 0,
    trucksList: null,
    trucksListOthers: null,
    loaded: false
  }
})
@Injectable()
export class SearchState {
  navigationTrigger: string;

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private searchApiService: SearchApiService,
    private localizeRouterService: LocalizeRouterService,
    private apiUtils: ApiUtils,
    private regionalSettingsService: RegionalSettingsService
  ) {
    router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        take(1)
      )
      .subscribe(
        (event: NavigationStart) => {
          this.navigationTrigger = event.navigationTrigger;
        });
  }

  getCurrentRegion(): string {
    const regionCode = this.store.selectSnapshot<LocalesStateInterface>((state: AppState) => state.language).region
      .curRegion;
    return regionCode.id;
  }
  getSingleCountryRegion(): boolean {
    const region = this.store.selectSnapshot((state: AppState) => state.language.region.curRegion.id);
    return this.store.selectSnapshot(
      (state: AppState) =>
        state.regionalSettings.regionsSettings.filter(f => f.region === region)[0].singleCountryRegion
    );
  }
  getCurrentCountry(): LocalesStateCountryList {
    const countryCode = this.store.selectSnapshot<LocalesStateInterface>((state: AppState) => state.language).country
      .curCountry;
    return countryCode;
  }
  getRegionalSettings(): RegionSettings {
    const regionalSettings = this.store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === this.getCurrentRegion())[0]);
    return regionalSettings;
  }
  @Action(GetSearch)
  getSearch({ getState, patchState }: StateContext<SearchStateInterface>, { payload }: GetSearch) {
    var searchQuery = this.createSearchQuery(payload);

    //Patching the state with loaded flag false to show the skeleton and return state after 100ms delay;
    patchState({
      loaded: false
    });

    return this.searchApiService.getSearch(searchQuery).subscribe((res: SearchResponse) => {
      res.hits = res.hits.map(h => (h = new Truck(h)));
      res.otherHits = res.otherHits.map(h => (h = new Truck(h)));

      const resp = {
        totalTrucks: res.total,
        totalOtherTrucks: res.grandTotal - res.total,
        grandTotalTrucks: res.grandTotal,
        trucksList: TrucksState.updateTrucksResponse(res.hits),
        trucksListOthers: TrucksState.updateTrucksResponse(res.otherHits),
        loaded: true,
        aggregations: res.aggregations
      };
      setTimeout(() => {
        patchState(resp);
      }, 100);
    });
  }

  @Action(GetAggregations)
  getAggregations({ getState, patchState }: StateContext<SearchStateInterface>, { payload }: GetSearch) {
    var searchQuery = this.createSearchQuery(payload);

    return this.searchApiService.getAggregations(searchQuery).subscribe((res: SearchResponse) => {
      patchState({
        aggregations: res.aggregations,
      });
    });
  }

  private createSearchQuery(payload: SearchStateParams) {
    let queryString = '';
    let site = '';
    let region = '';

    // Getting regional settings
    this.regionalSettingsService.getPortalName$.subscribe((res: any) => {
      site = res;
    });

    this.regionalSettingsService.getRegion$.subscribe((res: any) => {
      region = res;
    });

    // Checking for payload presence
    if (Boolean(payload)) {
      queryString = `?qf=${payload}`;
      localStorage.setItem('searchParam', JSON.stringify(payload));
    }

    // Decoding payload
    var decoded = JSON.parse(JSON.stringify(this.apiUtils.getDecodedParams(payload)));
    decoded.steps.advertisementCategory = decoded.steps.offerAndWarranty || { value: 'All' };
    var searchQuery = new SearchQuery();
    // Common properties
    searchQuery.freeText = decoded.q;
    searchQuery.page = decoded.page === undefined || decoded.page === "" ? 1 : parseInt(decoded.page);
    searchQuery.size = 12;
    searchQuery.site = site || this.getRegionalSettings().site;
    searchQuery.region = region || this.getCurrentRegion();

    // Function to handle setting properties from decoded.steps
    const setProperty = (key, searchQueryKey) => {
      if (decoded.steps[key]) {
        searchQuery[searchQueryKey] = decoded.steps[key].value;
      }
    };

    // Setting properties from decoded.steps
    const properties = [
      'requestingFilter', 'expandingFilter', 'stockLocationAddress',
      'stockLocationCity', 'stockLocationCountry', 'superstructureType',
      'majorCategory', 'axleConfiguration', 'make', 'cabType',
      'advertisementCategory', 'offerAndWarranty', 'model', 'emission',
      'fuelType'
    ];
    properties.forEach(prop => setProperty(prop, prop));
    // Special handling for properties with sub-objects
    const setSubProperty = (key, querySubKey, subKey, searchQueryKey, defaultValue) => {
      if (decoded.steps[key]) {
        // Ensure the object exists before adding or updating properties
        if (!searchQuery[searchQueryKey]) {
          searchQuery[searchQueryKey] = {};
        }

        // Add or update the sub-property within the existing object
        searchQuery[searchQueryKey][querySubKey] = decoded.steps[key].value[subKey] || defaultValue;
      }
    };

    // Setting sub-properties
    setSubProperty('mileageKm', 'maxMileage', 'max', 'mileage', Number.MAX_SAFE_INTEGER);
    setSubProperty('mileageKm', 'minMileage', 'min', 'mileage', 0);
    setSubProperty('horsePower', 'maxHorsePower', 'max', 'horsePower', Number.MAX_SAFE_INTEGER);
    setSubProperty('horsePower', 'minHorsePower', 'min', 'horsePower', 0);
    setSubProperty('modelYear', 'modelYearMax', 'max', 'modelYear', Number.MAX_SAFE_INTEGER);
    setSubProperty('modelYear', 'modelYearMin', 'min', 'modelYear', 0);
    setSubProperty('priceExclVatEuro', 'maxPrice', 'max', 'priceRange', Number.MAX_SAFE_INTEGER);
    setSubProperty('priceExclVatEuro', 'minPrice', 'min', 'priceRange', 0);
    setSubProperty('dateFirstRegistration', 'maxDateFirstRegistration', 'max', 'dateFirstRegistration', Number.MAX_SAFE_INTEGER);
    setSubProperty('dateFirstRegistration', 'minDateFirstRegistration', 'min', 'dateFirstRegistration', 0);

    // Sorting logic
    if (decoded.sort) {
      const lowest = decoded.sort.toLowerCase().includes('lowest');
      const price = decoded.sort.toLowerCase().includes('price');

      searchQuery.sortQuery = {
        direction: lowest ? Direction.Asc : Direction.Desc,
        sortField: price ? SortField.Price : SortField.PublishDate,
      };
    } else {
      searchQuery.sortQuery = {
        direction: Direction.Desc,
        sortField: SortField.PublishDate
      };
    }

    
    return searchQuery;
  }

  private generateQuery(payload: SearchStateParams) {
    let queryString = '';
    let site: string;
    let region: string;

    this.regionalSettingsService.getPortalName$.subscribe((res: any) => {
      site = res;
    });

    this.regionalSettingsService.getRegion$.subscribe((res: any) => {
      region = res;
    });

    if (Boolean(payload)) {
      queryString = payload.qf;
      localStorage.setItem('searchParam', JSON.stringify(payload));
    }
    var decoded = JSON.parse(JSON.stringify(this.apiUtils.getDecodedParams(payload)));
    var searchQuery = new SearchQuery();

    searchQuery.freeText = decoded.q;
    searchQuery.page = decoded.page === undefined || decoded.page === "" ? 1 : parseInt(decoded.page);
    searchQuery.size = 12;
    searchQuery.site = site;
    searchQuery.region = region;

    if (decoded.steps.requestingFilter) {
      searchQuery.requestingFilter = decoded.steps.requestingFilter.value;
    }

    if (decoded.steps.expandingFilter) {
      searchQuery.expandingFilter = decoded.steps.expandingFilter.value;
    }

    if (decoded.steps.stockLocationAddress) {
      searchQuery.stockLocationAddress = decoded.steps.stockLocationAddress.value;
    }

    if (decoded.steps.stockLocationCity) {
      searchQuery.stockLocationCity = decoded.steps.stockLocationCity.value;
    }

    if (decoded.steps.stockLocationCountry) {
      searchQuery.stockLocationCountry = decoded.steps.stockLocationCountry.value;
    }

    if (decoded.steps.superstructureType) {
      searchQuery.superstructureType = decoded.steps.superstructureType.value;
    }

    if (decoded.steps.majorCategory) {
      searchQuery.majorCategory = decoded.steps.majorCategory.value;
    }

    if (decoded.steps.axleConfiguration) {
      searchQuery.axleConfiguration = decoded.steps.axleConfiguration.value;
    }

    if (decoded.steps.make) {
      searchQuery.make = decoded.steps.make.value;
    }

    if (decoded.steps.cabType) {
      searchQuery.cabType = decoded.steps.cabType.value;
    }

    if (decoded.steps.advertisementCategory) {
      searchQuery.advertisementCategory = decoded.steps.advertisementCategory.value;
    }

    if (decoded.steps.offerAndWarranty) {
      searchQuery.offerAndWarranty = decoded.steps.offerAndWarranty.value;
    }

    if (decoded.steps.model) {
      searchQuery.model = decoded.steps.model.value;
    }

    if (decoded.steps.emission) {
      searchQuery.emission = decoded.steps.emission.value;
    }

    if (decoded.steps.mileageKm) {
      searchQuery.mileage = {
        maxMileage: decoded.steps.mileageKm ? decoded.steps.mileageKm.value.max : Number.MAX_SAFE_INTEGER,
        minMileage: decoded.steps.mileageKm ? decoded.steps.mileageKm.value.min : 0,
      };
    }

    if (decoded.steps.horsePower) {
      searchQuery.horsePower = {
        maxHorsePower: decoded.steps.horsePower ? decoded.steps.horsePower.value.max : Number.MAX_SAFE_INTEGER,
        minHorsePower: decoded.steps.horsePower ? decoded.steps.horsePower.value.min : 0,
      };
    }

    if (decoded.steps.modelYear) {
      searchQuery.modelYear = {
        modelYearMax: decoded.steps.modelYear ? decoded.steps.modelYear.value.max : Number.MAX_SAFE_INTEGER,
        modelYearMin: decoded.steps.modelYear ? decoded.steps.modelYear.value.min : 0,
      };
    }

    if (decoded.steps.priceExclVatEuro) {
      searchQuery.priceRange = {
        maxPrice: decoded.steps.priceExclVatEuro ? decoded.steps.priceExclVatEuro.value.max : Number.MAX_SAFE_INTEGER,
        minPrice: decoded.steps.priceExclVatEuro ? decoded.steps.priceExclVatEuro.value.min : 0,
      };
    }

    if (decoded.steps.dateFirstRegistration) {
      searchQuery.dateFirstRegistration = {
        maxDateFirstRegistration: decoded.steps.dateFirstRegistration ? decoded.steps.dateFirstRegistration.value.max : Number.MAX_SAFE_INTEGER,
        minDateFirstRegistration: decoded.steps.dateFirstRegistration ? decoded.steps.dateFirstRegistration.value.min : 0,
      };
    }

    if (decoded.sort) {
      const lowest = decoded.sort.toLowerCase().includes('lowest');
      const price = decoded.sort.toLowerCase().includes('price');

      searchQuery.sortQuery = {
        direction: lowest ? Direction.Asc : Direction.Desc,
        sortField: price ? SortField.Price : SortField.PublishDate,
      };
    } else {
      searchQuery.sortQuery = {
        direction: Direction.Desc,
        sortField: SortField.PublishDate
      };
    }
    return searchQuery;
  }

  @Action(SetSearchFromUrl)
  setSearchFromUrl({ getState, patchState }: StateContext<SearchStateInterface>, { payload }: SetSearchFromUrl) {
    const state: SearchStateInterface = getState();
    const newState: SearchStateInterface = cloneDeep(state);

    // update filters
    newState.filter = this.setFilterValues(newState.filter, payload);
    this.checkMajorCategoryType(newState.filter);

    // update qf params based on filters
    newState.params.qf = this.apiUtils.getEncodedParams(newState.filter);

    patchState({
      ...newState
    });
  }

  @Action(UpdateSearch)
  updateSearch({ getState, patchState }: StateContext<SearchStateInterface>, { payload }: UpdateSearch) {
    const state: SearchStateInterface = getState();
    const newState: SearchStateInterface = cloneDeep(state);
    // update filters
    newState.filter.q = payload && payload.q ? payload.q : '';
    newState.filter.page = payload && payload.page ? payload.page : 1;

    if (payload && payload.steps) {
      newState.filter.steps = payload.steps;
    }

    if (payload && payload.sort) {
      newState.filter.sort = payload.sort;
    }

    const region = this.store.selectSnapshot((state: AppState) => state.language.region.curRegion.id);
    const isSingleCountryRegion = this.store.selectSnapshot(
      (state: AppState) =>
        state.regionalSettings.regionsSettings.filter(f => f.region === region)[0].singleCountryRegion
    );

    if (isSingleCountryRegion) {
      newState.filter.steps.stockLocationCountry.isSelected = false;
      newState.filter.steps.stockLocationCountry.isVisible = false;
    }
    // update qf params based on filters
    newState.params.qf = this.apiUtils.getEncodedParams(newState.filter);
    this.updateUrlParams(newState.params);

    patchState({
      ...newState
    });
  }

  @Action(SetDefaultCategory)
  setDefaultCategory({ getState, patchState }: StateContext<SearchStateInterface>, { payload }: SetDefaultCategory) {
    const state: SearchStateInterface = getState();
    const newState: SearchStateInterface = cloneDeep(state);
    newState.filter.steps['advertisementCategory'] = {
      id: uuidv4(),
      isSelected: false,
      isVisible: false,
      isExpanded: false,
      title: 'Offer',
      inputPlaceholder: '',
      value: payload,
      initValue: 'All',
      list: [
        {
          name: 'Volvo Selected',
          id: 'VolvoSelected'
        },
        {
          name: 'Volvo Approved',
          id: 'VolvoApproved'
        },
        {
          name: 'Volvo Economy',
          id: 'VolvoEconomy'
        },
        {
          name: 'Trade and Export',
          id: 'TradeExport'
        }
      ],
    };

    newState.filter.page = 1;
    newState.params.qf = this.apiUtils.getEncodedParams(newState.filter);

    this.updateUrlParams(newState.params);
    patchState({
      ...newState,
    });
  }

  @Action(UpdateStep)
  updateStep({ getState, patchState }: StateContext<SearchStateInterface>, { payload }: UpdateStep) {

    if (this.navigationTrigger === 'popstate') {
      return;
    }
    const reloadFlag = payload.reloadFlag;
    const state: SearchStateInterface = getState();
    const newState: SearchStateInterface = cloneDeep(state);
    // update filters
    if (payload.value.value) {
      if (payload.key != 'mileageKm' && payload.key != 'priceExclVatEuro' && payload.key != 'dateFirstRegistration' && payload.key != 'horsePower') {
        let payloadValue = payload.value.value as string;
        payload.value.value = payloadValue.split(";").sort().join(";");
      }
    }
    newState.filter.steps[payload.key] = payload.value;
    newState.filter.requestingFilter = payload.key;
    newState.filter.expandingFilter = payload.expandingFilter;
    if (newState.filter.expandingFilter !== '') {
      newState.filter.requestingFilter = '';
    }

    if (this.navigationTrigger !== 'popstate') {
      newState.filter.page = 1;
    }
    //This condition is checked to retain the page number when page is refreshed or when breadcrumb Our Stock is clicked. 
    if (reloadFlag && newState.params && newState.params.qf) {
      var decoded = JSON.parse(JSON.stringify(this.apiUtils.getDecodedParamsforPageReload(newState.params)));
      newState.filter.page = decoded.page;
    }
    else
      newState.filter.page = 1;
    const AllValue = 'All';
    newState.filter.steps[payload.key].isSelected = Boolean(
      newState.filter.steps[payload.key].value !== AllValue && newState.filter.steps[payload.key].value
    );

    const region = this.store.selectSnapshot((state: AppState) => state.language.region.curRegion.id);
    const isSingleCountryRegion = this.store.selectSnapshot(
      (state: AppState) =>
        state.regionalSettings.regionsSettings.filter(f => f.region === region)[0].singleCountryRegion
    );

    if (isSingleCountryRegion) {
      newState.filter.steps.stockLocationCountry.isSelected = false;
      newState.filter.steps.stockLocationCountry.isVisible = false;
    }

    this.checkMajorCategoryType(newState.filter);
    this.checkVisibilityOfOfferAndWarranty(newState.filter);
    // update qf params based on filters
    newState.params.qf = this.apiUtils.getEncodedParams(newState.filter);
    this.updateUrlParams(newState.params);
    patchState({
      ...newState
    });
  }

  @Action(ResetStep)
  resetStep({ getState, patchState }: StateContext<SearchStateInterface>, { payload, removedChip }: ResetStep) {
    const state: SearchStateInterface = getState();
    const newState: SearchStateInterface = cloneDeep(state);

    // update filters
      if (typeof removedChip === 'string')
        newState.filter.steps[payload].value = newState.filter.steps[payload].value.replace(removedChip, '')
      if (removedChip === '' || typeof removedChip !== 'string') {
          newState.filter.steps[payload].value = isObject(newState.filter.steps[payload].initValue)
              ? { ...newState.filter.steps[payload].initValue }
              : newState.filter.steps[payload].initValue;
          newState.filter.steps[payload].isSelected = false;
      }
      else {          
          var filterValue = isObject(newState.filter.steps[payload].value)
              ? { ...newState.filter.steps[payload].value }
              : newState.filter.steps[payload].value;
          filterValue = this.cleanSemicolons(filterValue)          
          newState.filter.steps[payload].value = filterValue;
          if (filterValue === '') newState.filter.steps[payload].isSelected = false;
      }
      //Update the requesting filter to  - source of requesting(payload)
      newState.filter.requestingFilter = payload;
    this.checkMajorCategoryType(newState.filter);

    // update qf params based on filters
    newState.params.qf = this.apiUtils.getEncodedParams(newState.filter);
    this.updateUrlParams(newState.params);

    patchState({
      ...newState
    });
  }

    cleanSemicolons(input: string): string {
        // Replace multiple consecutive semicolons with a single semicolon
        let result = input.replace(/;+/, ';');

        // Remove semicolons at the beginning
        if (result.startsWith(';')) {
            result = result.substring(1);
        }

        // Remove semicolons at the end
        if (result.endsWith(';')) {
            result = result.slice(0, -1);
        }

        return result;
    }
  updateUrlParams(params: SearchStateParams): void {
    this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.search)], {
      relativeTo: this.activatedRoute,
      queryParams: { qf: params.qf }
      //queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  private checkMajorCategoryType(search: SearchStateFilter): void {
    this.checkVisibilityOfSuperstructure(search);
    this.checkVisibilityOfTrailerSemiTrailer(search);
  }

  private checkVisibilityOfSuperstructure(search: SearchStateFilter): void {
    const majorCategoryValue = 'Tractor';
    const isMajorCategoryTruckSelected =
      search.steps.majorCategory.isSelected && search.steps.majorCategory.value === majorCategoryValue;

    if (!isMajorCategoryTruckSelected) {
      this.makeVisibleStep(search.steps.superstructureType);
    } else {
      this.resetHideStep(search.steps.superstructureType);
    }
  }

  private checkVisibilityOfOfferAndWarranty(search: SearchStateFilter): void {
    const isSelectedOrApproved = true;
    // search.steps.advertisementCategory.value === 'Selected;Approved';

    if (isSelectedOrApproved) {
      this.makeVisibleStep(search.steps.offerAndWarranty);
    } else {
      this.resetHideStep(search.steps.offerAndWarranty);
    }
  }

  private checkVisibilityOfTrailerSemiTrailer(search: SearchStateFilter): void {
    const trailer = 'Trailer';
    const semiTrailer = 'Semi-trailer';
    const majorCategoryStep = search.steps.majorCategory;
    const isMajorCategoryTruckSelected =
      majorCategoryStep.isSelected && (majorCategoryStep.value === trailer || majorCategoryStep.value === semiTrailer);

    if (isMajorCategoryTruckSelected) {
      this.resetHideStep(search.steps.make);
      this.resetHideStep(search.steps.model);
      this.resetHideStep(search.steps.horsePower);
      this.resetHideStep(search.steps.mileageKm);
      this.resetHideStep(search.steps.emission);
      this.resetHideStep(search.steps.fuelType);
    } else {
      this.makeVisibleStep(search.steps.make);
      this.makeVisibleStep(search.steps.model);
      this.makeVisibleStep(search.steps.horsePower);
      this.makeVisibleStep(search.steps.mileageKm);
      this.makeVisibleStep(search.steps.emission);
      this.makeVisibleStep(search.steps.fuelType);
    }
  }

  private resetHideStep(searchStep: SearchStep): void {
    searchStep.isSelected = false;
    searchStep.isExpanded = false;
    searchStep.isVisible = false;
    searchStep.value = searchStep.initValue;
  }

  private makeVisibleStep(searchStep: SearchStep): void {
    searchStep.isVisible = true;
  }

  private setFilterValues(search: SearchStateFilter, searchParams: SearchStateParams): SearchStateFilter {
    const params = this.apiUtils.getDecodedParams(searchParams);
    return {
      q: params.q,
      page: Number(params.page || 1),
      sort: params.sort || SortOptions.oldestAge,
      steps: merge(search.steps, params.steps),
      requestingFilter: search.requestingFilter,
      expandingFilter: search.expandingFilter,
      defaultCategory: search.defaultCategory
    };
  }

  setPriceFilterValue(): number {
    if (this.getCurrentRegion() === 'ZA')
      return 5000000;
    else
      return 300000;
  }

  checkAndSetZarPrice(searchData: any) {
    
    if (this.getRegionalSettings()?.zarPriceEnabled) {
      const searchObj = JSON.parse(JSON.stringify(searchData));
      const priveMaxValue = this.setPriceFilterValue();
      searchObj.steps.priceExclVatEuro.initValue.max = priveMaxValue;
      if (!searchData.steps.priceExclVatEuro.isSelected) {
        searchObj.steps.priceExclVatEuro.value.max = priveMaxValue;
      }
      searchObj.steps.priceExclVatEuro.options.units = 'R';
      return searchObj;
    }
    return searchData;
  }
}
