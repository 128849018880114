<div *ngIf="data" class="addheight sharePopup">
  <div class="sharePopup-padding24 sharePopup-flexDisplay">
    <span> 
        <h1 class="sharePopup-heading">{{ 'popups/share/title' | lowercase | translate }}</h1>
    </span>
      <img [src]="assetService.getAssetUrl('assets/pages/popups/Close.svg')" alt="Close" (click)="onClose()" class="Share-close"/>
      <!-- <i (click)="onClose()" class="Share-close material-icons ">close</i> -->
  </div>

  <div class="Share-items">
      <div class="Share-item mobileMargin" (click)="shareEmail();shareCount('Email');">
        <button  mat-button>
            <img [src]="assetService.getAssetUrl('assets/pages/popups/Mail.svg')" alt="Mail" class="mailImage"/>
        </button>
        <span class="Share-itemName">Email</span>
         
      </div>
      <div class="Share-item mobileMargin" (click)="shareFaceBook();shareCount('Facebook');">
          <button  mat-button>
              <img [src]="assetService.getAssetUrl('assets/pages/popups/Facebook.svg')" alt="Facebook" class="mailImage"/>
          </button>
          <span class="Share-itemName">Facebook</span>
      </div>
      <div (click)="shareTwitter();shareCount('Twitter');" class="Share-item mobileMargin">
          <button  mat-button>
              <img [src]="assetService.getAssetUrl('assets/pages/popups/Twitter.svg')" alt="X" class="mailImage"/>
          </button>
          <span class="Share-itemName">X</span>
      </div>
      <div (click)="shareWhatsApp();shareCount('WhatsApp');" class="Share-item">
          <button mat-button>
              <img [src]="assetService.getAssetUrl('assets/pages/popups/WhatsApp.svg')" alt="WhatsApp" class="mailImage"/>
          </button>
          <span class="Share-itemName">WhatsApp</span>
      </div>
      <div (click)="shareLinkedIn();shareCount('LinkedIn');"  class="Share-item">
          <button mat-button>
              <img [src]="assetService.getAssetUrl('assets/pages/popups/LinkedIn.svg')" alt="LinkedIn" class="mailImage"/>
          </button>
          <span class="Share-itemName">LinkedIn</span>
      </div>
      <div (click)="shareInstagram();shareCount('LinkedIn');" class="Share-item">
        <button mat-button>
            <img [src]="assetService.getAssetUrl('assets/pages/popups/Instagram.svg')" alt="Instagram" class="mailImage"/>
        </button>
        <span class="Share-itemName">Instagram</span>
    </div>
  </div>
  <div class="Share-itemName ff-medium">{{ 'popups/share/copyTruckUrl' | lowercase | translate }}</div>
  <div class="copyUrlDiv">
    <div class="Share-copyUrl">{{ data.truckUrl }}</div>
    <button class="copy-button" (click)="copyToClipBoard()">Copy</button>
  </div>
  
</div> 
